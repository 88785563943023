import { useEffect, useState } from 'react';
import { setLocalStorage, getLocalStorage } from 'utils/general';
import { FAV_IMAGES } from 'constants/general';

export default function useFavouriteImage() {
  const cashedFavImages = getLocalStorage(FAV_IMAGES);
  const [favourites, setFavourites] = useState(cashedFavImages || []);

  useEffect(() => {
    setLocalStorage(FAV_IMAGES, favourites);
  }, [favourites]);

  const favouriteExist = (id) =>
    favourites.length > 0 && favourites.includes(id) ? true : false;

  const onFavouriteClick = (id) => {
    if (favouriteExist(id)) {
      const newFavourites = favourites.filter((item) => item !== id);
      setFavourites(newFavourites);
    } else {
      setFavourites([...favourites, id]);
    }
  };

  return { favouriteExist, onFavouriteClick };
}
