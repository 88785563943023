import { API_KEY, PAGE_SIZE, SEARCH_TEXT } from 'constants/general';

export const flickrApi = `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=${API_KEY}&extras=owner_name,url_s,url_m&text=${SEARCH_TEXT}&sort=relevance&per_page=${PAGE_SIZE}&format=json&nojsoncallback=1&`;

export const setLocalStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return JSON.parse(value);
};
