import { useEffect, useState } from 'react';
import { flickrApi } from 'utils/general';
import { get } from 'utils/api';

export default function useGetImages(page) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    const getImages = async () => {
      try {
        const data = await get(flickrApi, { page });
        setImages((prevImages) => {
          const prevIds = prevImages.map((item) => item.id);
          const newItems = data.photos.photo.filter(
            (item) => !prevIds.includes(item.id),
          );
          return [...prevImages, ...newItems];
        });

        setHasMore(data.photos.pages > page);
        setLoading(false);
      } catch (error) {
        setError(error.message);
      }
    };

    getImages();
  }, [page]);

  return { loading, error, images, hasMore };
}
