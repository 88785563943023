import { useState } from 'react';
import styled from 'styled-components';
import { device } from 'styles/styles';
import useGetImages from 'hooks/useGetImages';
import useFavouriteImage from 'hooks/useFavouriteImage';
import Image from 'components/Image/Image';
import Spinner from 'components/Spinner/Spinner';
import ElementInView from 'components/ElementInView/ElementInView';

const Container = styled.div`
  background: #e6e7e6;
  padding: 24px 28px;
  min-height: calc(100vh - 32px - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Grid = styled.div`
  display: grid;
  grid-gap: 1.75rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const GridItem = styled.div`
  height: 198px;
  width: 264px;
`;
const Center = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

function App() {
  const [page, setPage] = useState(1);
  const { loading, error, images, hasMore } = useGetImages(page);
  const { onFavouriteClick, favouriteExist } = useFavouriteImage();

  return (
    <Container>
      <Grid>
        {images.map((image, index) => {
          return (
            <GridItem key={image.id}>
              <Image
                title={image.title}
                author={image.ownername}
                src={{ m: image.url_m, s: image.url_s }}
                favourite={favouriteExist(image.id)}
                onClick={() => onFavouriteClick(image.id)}
              />
              <ElementInView
                view={images.length - 3 === index}
                onView={() => hasMore && setPage((prevPage) => prevPage + 1)}
                cancel={loading}
              />
            </GridItem>
          );
        })}
      </Grid>
      <Center>
        {loading && <Spinner />}
        {error && 'Something went wrong! Please reload the page.'}
      </Center>
    </Container>
  );
}

export default App;
