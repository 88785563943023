import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { size } from 'styles/styles';
import ElementInView from 'components/ElementInView/ElementInView';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 4px;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2);
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.6;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
`;
const ImageCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(60, 60, 60, 0.6);

  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;
const Title = styled.p`
  margin: 0;
  font-weight: bold;
  text-align: center;

  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Author = styled.p`
  margin: 0;
  font-style: italic;
`;
const Divider = styled.hr`
  border-top: 1px solid white;
  width: 52px;
`;
const Button = styled.button`
  border: 1px solid white;
  background: transparent;
  border-radius: 50px;
  padding: 11px 8px 9px 8px;
  color: white;
  width: 100px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  position: absolute;
  bottom: 16px;
`;

export default function Image({ title, author, src, favourite, onClick }) {
  const [loadImage, setLoadImage] = useState(false);

  return (
    <Container>
      <ElementInView view={true} onView={() => setLoadImage(true)} />
      {loadImage ? (
        <picture>
          <source srcSet={src.s} media={`(max-width: ${size.tablet})`} />
          <Img src={src.m} alt="image" />
        </picture>
      ) : (
        <Placeholder />
      )}
      <ImageCover>
        <Title>{title}</Title>
        <Divider />
        <Author>{author}</Author>
        <Button onClick={onClick}>
          {favourite ? 'Unfavourite' : 'Favourite'}
        </Button>
      </ImageCover>
    </Container>
  );
}

Image.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  src: PropTypes.object.isRequired,
  favourite: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
