export async function get(url, params) {
  const response = await fetch(url + new URLSearchParams(params));

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();

  return data;
}
