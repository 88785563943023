import { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

export default function ElementInView({ view, onView, cancel }) {
  const observer = useRef();
  const elementRef = useCallback(
    (node) => {
      if (cancel) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onView();
        }
      });
      if (node) observer.current.observe(node);
    },

    [cancel, onView],
  );

  return <>{view ? <div ref={elementRef}></div> : ''}</>;
}

ElementInView.propTypes = {
  view: PropTypes.bool.isRequired,
  onView: PropTypes.func.isRequired,
  cancel: PropTypes.bool,
};
